import React from 'react';
import styled from 'styled-components';

const Section = styled.section``;

const NotFound = () => {
  //
  return (
    <Section>
      <div className="container">
        <div className="block">
          <h1>404</h1>
        </div>
      </div>
    </Section>
  );
};

export default NotFound;

import React from 'react';
import PropTypes from 'prop-types';
import { PageSEO, useSEO } from '../components/SEO';
import Hero from '../page-sections/_misc/404';

const NotFound = ({ location }) => {
  //
  const { pathname } = location;
  const seo = useSEO(pathname);

  return (
    <article>
      <PageSEO title={seo.title} description={seo.description} pathname={pathname} featureImage="og-404.jpg" />
      <Hero />
    </article>
  );
};

export default NotFound;

NotFound.propTypes = {
  location: PropTypes.object.isRequired,
};
